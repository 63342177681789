.App {
  text-align: center;
  background-color: #282c34;
}
.Body {
  text-align: center;
  background-color: #282c34;
  color: #bababa;
  font: 300;
}

.App-logo {
  height: 40vmin;
  opacity: 0.5;
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: pulse 2s infinite;
  }
}

.App-header {
  background-color: #2e3bf2;
  background-color: #f22e31;
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: rgb(202, 202, 202);
  min-height: 40vh;

  /* background-image: linear-gradient(to bottom, #2e3bf2, #282c34); */
}
.Header-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #bababa;
  text-shadow: rgb(186, 186, 186) 1px 0 10px;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

p {
  font-size: 50;
}
